import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import { Link } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import RichTextRenderer from '~/components/ContentfulRichTextRender'

const Page = ({ data }) => {
  const { blocks } = { ...data.contentfulNewPage }

  const HeroBanner = ({ order, data }) => {
    const { title, subheading, fontColor, image, buttonTitle, buttonLink } = { ...data }

    return (
      <div className="container-fluid overflow-hidden px-0" style={{ marginTop: order > 0 ? '0px' : '100px' }}>
        <div className="row">
          <div
            className="img-fluid ambassadorNewsBanner"
            style={{
              backgroundColor: 'white',
              backgroundImage: `url('${image && getSrc(image)}')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'top'
            }}
          >
            <div
              className="col-12 col-md-6"
              style={{ paddingLeft: '3.5rem', paddingTop: '3.5rem', paddingRight: '3.5rem' }}
            >
              <h1 className="bannerImageTitle" style={{ color: fontColor }}>
                {title}
              </h1>
              <h2 className="bannerImageSubtitle text-uppercase" style={{ color: fontColor }}>
                {subheading}
              </h2>
              {buttonTitle && buttonLink && (
                <Link className="bannerImageCTA d-flex align-items-center justify-content-center w-100" to={buttonLink}>
                  {buttonTitle}
                </Link>
              )}
            </div>
            <div className="col-12 col-md-6" />
          </div>
        </div>
      </div>
    )
  }

  const TextBlock = data => {
    const { title, content, ctaButton } = { ...data.data }

    return (
      <div className="container-fluid mt-4 mb-5">
        <div className="row px-5">
          <div className="col-12 col-md-9">
            {title && <div className="OSL72 darkFont mb-4">{title}</div>}

            <div className="SFPRO20R darkFont">
              <RichTextRenderer richText={content} />
            </div>
            {ctaButton && (
              <div className="mt-5 mediaBlockCTA fiftyTo100">
                <Link className="d-flex align-items-center justify-content-center w-100" to={ctaButton.slug}>
                  {ctaButton.title}
                </Link>
              </div>
            )}
          </div>
          <div className="col-12 col-md-3" />
        </div>
      </div>
    )
  }

  return (
    <Layout>
      <Metadata title="Become an Ambassador" />
      {blocks.map((block, index) => {
        if (block.__typename === 'ContentfulImageWithOverlayText')
          return <HeroBanner key={index} order={index} data={block} />

        if (block.__typename === 'ContentfulTextBlock') return <TextBlock key={index} data={block} />
      })}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query NewPageAmbassadorOnboardQuery {
    contentfulNewPage(title: { eq: "Ambassador Onboard" }) {
      title
      blocks {
        ... on ContentfulImageWithOverlayText {
          id
          __typename
          fontColor
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          title
          subheading
          textBlockWidth
          buttonTitle
          buttonLink
        }
        ... on ContentfulTextBlock {
          __typename
          title
          content {
            raw
          }
          ctaButton {
            title
            slug
          }
        }
      }
    }
  }
`
